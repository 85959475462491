import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const PermissionDenied = () => {
  const { workspace_id } = useParams();
  const location = useLocation();

  useEffect(() => {
    // This function will be called whenever the location changes
    const isRedirectedFromRouter = location.state?.from === "react-router-dom";
    const isAnchorTagPress = !location.state;
    const navigationEntries = performance.getEntriesByType("navigation");
    const isReloaded = navigationEntries.length > 0 && navigationEntries[0].type === "reload";

    if (isRedirectedFromRouter) {
      // console.log('Redirected from React Router DOM');
    } else if (isReloaded) {
      // console.log('Page was reloaded');
      // navigate("/")
    } else if (isAnchorTagPress) {
      // console.log('Navigated using anchor tag');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="flex min-h-full flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md">
        <a
          href="/"
          className="group flex cursor-pointer items-center gap-x-5 rounded-md bg-gray-50 px-3 py-2 shadow-md transition-all duration-150 hover:bg-gray-100 hover:shadow-lg">
          <p className="flex h-full items-center text-2xl text-gray-400 transition-all duration-150 group-hover:text-gray-500">Refresh</p>
          <ArrowPathIcon className="mr-2 h-7 w-7 text-lg text-gray-400 transition-all duration-150 group-hover:text-gray-500" />
        </a>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">{workspace_id ? "No content assigned" : "Sorry, you don't have any access to content yet."}</h2>
        <div className="text-regular mt-1 text-center text-gray-600">Contact us and request access to your pages and analytics.</div>
      </div>
    </div>
  );
};

export default PermissionDenied;
