import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import { AuthContext } from "src/context/auth";
import { clearCookies } from "src/helpers";

const Logout = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    localStorage.clear();
    clearCookies()
    setUser(null);
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center">
      <Preloader />
    </div>
  );
};

export default Logout;
