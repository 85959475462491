import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Image from "src/components/elements/Image";
import LeftMenuItemLink from "src/components/navigation/leftNavigation/LeftMenuItemLink";
import { isSVGString } from "src/helpers";
import { classNames } from "src/helpers/classNames";

const LeftMenuItemGroupTitle = ({ data, imageClassName, setImageClassName, group_id, page_id, isUnderLine, avoidUnderlineHover = false, setIsSubmenu, isSubmenu, searchTerm, dropdownArrowClass }) => {
  return (
    <>
      {data.submenu.length > 1 || data.subMenuFilteredDown ? (
        <button
          type="button"
          className="duration-20 flex h-10 w-full items-center justify-between space-x-1 rounded px-3 text-leftNavTextColor transition-all"
          onClick={() => setIsSubmenu(!isSubmenu)}>
          <div className="flex items-center">
            {data.image && (
              <div className={classNames("nav-icon mr-3 h-5 w-5 flex-shrink-0", imageClassName)}>
                {isSVGString(data.image) ? (
                  <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: data.image }}
                  />
                ) : (
                  <Image
                    src={data.image}
                    alt={"Menu image"}
                    classes={imageClassName}
                    onError={() => setImageClassName("hidden")}
                  />
                )}
              </div>
            )}
            <span
              className={classNames(
                "flex w-full items-center text-left font-medium !leading-none",
                data.type === "group" && data.submenu[0].type === "internal" ? "" : !avoidUnderlineHover ? "visited:text-purple-700 group-hover/link:underline" : "",
                isUnderLine && !avoidUnderlineHover ? "group-hover/link:underline" : "",
              )}>
              {data.submenu.length > 1 || data.subMenuFilteredDown ? data.name : data.submenu[0].name}
            </span>
          </div>
          <ChevronDownIcon className={classNames("h-7 w-7 rounded-md stroke-2 p-1 transition-all duration-200", dropdownArrowClass)} />
        </button>
      ) : (
        <>
          <LeftMenuItemLink
            data={data.submenu[0]}
            image={data.image || null}
            group_id={group_id}
            page_id={page_id}
            searchTerm={searchTerm}
            isTopLevel={true}
            imageClassName={imageClassName}
            setImageClassName={setImageClassName}
          />
        </>
      )}
    </>
  );
};

export default LeftMenuItemGroupTitle;
