import { ArrowRightStartOnRectangleIcon, Bars3BottomLeftIcon, KeyIcon } from "@heroicons/react/24/outline";
import isDarkColor from "is-dark-color";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "src/assets/images/logo.png";
import UserImage from "src/assets/images/user.jpg";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import Image from "src/components/elements/Image";
import DropdownWrapper from "src/components/form/DropdownWrapper";
import Input from "src/components/form/Input";
import Modal from "src/components/shared/Modal";
import UserDropdown from "src/components/shared/UserDropdown";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import { setBrowserStyleProperties } from "src/helpers/setBrowserStyleProperties";
import ResetPasswordForm from "src/pages/forgot-password/ResetPasswordForm";

const Header = ({ isMenu, setIsMenu, menuType, menus = [] }) => {
  const { workspace_id } = useParams();

  const navigate = useNavigate();
  const { site } = useContext(SiteContext);
  const { list: workspaces, details: workspaceDetails, setDetails: setWorkspaceDetails, setLoading: setWorkspaceDetailsLoading } = useContext(WorkspaceContext);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [currentLogo, setCurrentLogo] = useState("");

  const passwordValidation = !password || !confirmPassword || !oldPassword || password !== confirmPassword || !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_])[^\s]{8,}$/.test(password);

  const logout = () => {
    logoutRedirect();
  };

  const userMenu = {
    image: UserImage,
    name: "Administrator",
    dropdownData: [
      {
        _id: 2,
        icon: KeyIcon,
        label: "Change password",
        standout: false,
        onclick: () => setIsOpenModal(true),
      },
      {
        _id: 3,
        icon: ArrowRightStartOnRectangleIcon,
        label: "Logout",
        onclick: logout,
        standout: false,
      },
    ],
  };

  const clearChangePasswordModalValues = () => {
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    if (!isOpenModal) {
      clearChangePasswordModalValues();
    }
  }, [isOpenModal]);

  const changePassword = async () => {
    setDisabled(true);

    try {
      const { data: resData } = await apiRequest("post", "/portal/user/self/change-password", {
        body: {
          old_password: oldPassword,
          password,
          confirm_password: confirmPassword,
        },
      });

      if (resData.status === 200) {
        toast.success(resData.message);
        setIsOpenModal(false);

        clearChangePasswordModalValues();
      } else {
        toast.error(resData.message);
        clearChangePasswordModalValues();
      }
    } catch (err) {
      toast.error(err.message);
      clearChangePasswordModalValues();
    } finally {
      setDisabled(false);
    }
  };

  const clickWorkspace = async (workspace = {}) => {
    let workspaceId = workspace?._id;

    if (!workspaceId) {
      throw new Error("No workspace available to fetch.");
    }

    if ("" + workspaceId === "" + workspaceDetails?._id) {
      return;
    }

    // set the workspace, group and page to localstorage
    localStorage.setItem("workspace", workspace?.slug || workspace?._id || "");
    localStorage.setItem("group", "");
    localStorage.setItem("page", "");

    setWorkspaceDetailsLoading(true);

    try {
      const { data: resData } = await apiRequest("GET", `/portal/users/${workspaceId}/page`);

      if (resData.status === 200) {
        if (workspaceDetails._id !== workspace._id) {
          setWorkspaceDetails(workspace);
        }
        navigate(resData.link);
      } else {
        navigate("/permission-denied");
      }
    } catch (err) {
      navigate("/permission-denied");
    } finally {
      setWorkspaceDetailsLoading(false);
    }
  };

  function extractWorkspaceName() {
    // Assuming the base structure of the URL is "http://localhost:3002/workspace/{workspace-name}/..."
    const url = window.location.href;
    const pathSegments = url.split("/");

    const workspaceIndex = pathSegments.findIndex((segment) => segment === "workspace");

    if (workspaceIndex !== -1 && workspaceIndex < pathSegments.length - 1) {
      const workspaceName = pathSegments[workspaceIndex + 1];
      return workspaceName;
    }

    return null;
  }

  function loadImage() {
    // let src = site?.image_logo ? `${site.image_logo}?${new Date().getTime()}` : Logo;
    let src = site?.image_logo ? site.image_logo : Logo;
    let setting = site;
    if (workspace_id && !workspaceDetails?.use_global_settings) {
      // src = workspaceDetails?.image_logo ? `${workspaceDetails.image_logo}?${new Date().getTime()}` : Logo;
      src = workspaceDetails?.image_logo ? workspaceDetails.image_logo : Logo;
      setting = workspaceDetails;
    }
    setCurrentLogo(src);
    setBrowserStyleProperties(document, setting);
  }

  useEffect(() => {
    loadImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?._id, site?._id, workspace_id]);

  return (
    <>
      <div className={classNames("fixed left-0 top-0 z-30 flex h-16 w-full items-center bg-headerColor transition-all", workspaceDetails.workspace_type !== "JWT_FULL_EMBED" || !isDarkColor(workspaceDetails.top_bar_color) ? "border-b border-gray-200" : "")}>
        <div className="relative flex h-full w-full items-center justify-between pl-4 pr-6">
          <div className="flex items-center">
            {!(!workspaceDetails?._id || !menus?.length || menus?.length === 1) && (
              <button
                onClick={() => setIsMenu(!isMenu)}
                className={classNames("group group -ml-1 mr-2 flex h-9 w-9 items-center  justify-center rounded-md transition-all", menuType === "vertical" ? "" : "md:hidden")}>
                <Bars3BottomLeftIcon className="h-7 w-7 text-headerTextColor transition-all group-hover:stroke-2" />
              </button>
            )}
            {workspace_id && workspaceDetails._id && workspaces.length > 1 ? (
              <div>
                <DropdownWrapper
                  data={workspaces.map((workspace) => ({
                    ...workspace,
                    image: workspace.square_logo || workspace.image_logo || workspace.image_favicon,
                  }))}
                  onClick={clickWorkspace}
                  currentId={extractWorkspaceName()}
                  content={
                    <div className="mb-[1px] h-12 sm:h-14 sm:py-[3px]">
                      <Image
                        src={currentLogo}
                        alt={"logo"}
                        width="100%"
                        height="100%"
                        effect="blur"
                        classes="object-contain object-left"
                        hideImageOnError
                      />
                    </div>
                  }
                  buttonLabel="Workspaces"
                  buttonClass="w-full max-w-sm"
                  buttonIcon={true}
                  dropdownContainer="ml-11"
                />
              </div>
            ) : (
              <div className="sm:h-13 h-12 sm:py-[3px]">
                <Image
                  src={currentLogo}
                  alt={"logo"}
                  width="100%"
                  height="100%"
                  effect="blur"
                  classes="object-contain object-left"
                  hideImageOnError
                />
              </div>
            )}
          </div>
          <div className="ml-auto">
            <UserDropdown
              data={userMenu}
              xPlacement={"right"}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Change Password"
        isOpen={isOpenModal}
        onSuccess={changePassword}
        onCancel={() => setIsOpenModal(false)}
        defaultOptions={{
          onSuccessLoaderVisible: disabled,
          onSuccessLoaderStart: disabled,
          successButtonDisabled: disabled || passwordValidation,
        }}>
        <div className="mb-3">
          <Input
            inline={true}
            inputValue={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            label={"Current Password *"}
            inputType={"password"}
            inputName={"password"}
          />
        </div>
        <hr className="my-5" />
        <ResetPasswordForm
          password={password}
          confirmPassword={confirmPassword}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
        />
      </Modal>
    </>
  );
};

export default Header;
