import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Image from "src/components/elements/Image";
import { isSVGString } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import TopMenuItemLink from "./TopMenuItemLink";

const TopMenuItemGroupTitle = ({
  data,
  imageClassName,
  setImageClassName,
  group_id,
  page_id,
  isUnderLine,
  avoidUnderlineHover = false,
  setIsSubmenu,
  isSubmenu,
  searchTerm,
  handleClick = () => {},
  clickMainMenu = () => {},
  windowWidth = 0,
  dropdownArrowClass,
  isSubmenuSearchOpened
}) => {
  return (
    <>
      {data.submenu.length > 1 || data.subMenuFilteredDown ? (
        <button
          type="button"
          className={classNames(
            "duration-20 group flex h-10 w-full items-center justify-between space-x-1 rounded px-3 transition-all hover:text-leftNavTextColor md:text-base",
            data._id === group_id || data.slug === group_id ? "!bg-leftNavActiveColor !text-leftNavActiveTextColor" : "text-leftNavTextColor",
            windowWidth > 760 ? "group-hover/item:!bg-leftNavActiveColor group-hover/item:!text-leftNavActiveTextColor" : "",
            isSubmenu ? "!bg-leftNavActiveColor !text-leftNavActiveTextColor" : "text-leftNavTextColor",
          )}
          onClick={clickMainMenu}
          >
          <div className="flex items-center">
            {data.image && (
              <div className={classNames("nav-icon mr-3 h-5 w-5 flex-shrink-0", imageClassName)}>
                {isSVGString(data.image) ? (
                  <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: data.image }}
                  />
                ) : (
                  <Image
                    src={data.image}
                    alt={"Menu image"}
                    classes={imageClassName}
                    onError={() => setImageClassName("hidden")}
                  />
                )}
              </div>
            )}
            <span className={classNames("flex w-full items-center text-left font-medium", data.type === "group" && data.submenu[0].type === "internal" ? "" : "group-hover:text-leftNavActiveTextColor", isUnderLine && !avoidUnderlineHover ? "group-hover:underline" : "")}>
              {data.submenu.length > 1 || data.subMenuFilteredDown ? data.name : data.submenu[0].name}
            </span>
          </div>
          <ChevronDownIcon className={classNames("h-7 w-7 rounded-md stroke-2 p-1 md:h-6 md:w-6", windowWidth > 760 ? "group-hover/item:-rotate-0" : "", dropdownArrowClass)} />
        </button>
      ) : (
        <>
          <TopMenuItemLink
            handleClick={handleClick}
            data={data.submenu[0]}
            group_id={group_id}
            page_id={page_id}
            searchTerm={searchTerm}
            isTopLevel={true}
          />
        </>
      )}
    </>
  );
};

export default TopMenuItemGroupTitle;
